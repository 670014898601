@keyframes spinnerRotate {
    0%,
    100% {
        transform: rotate(-45deg);
    }
    25% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(45deg);
    }
    75% {
        transform: rotate(0deg);
    }
}

.wrapper {
    @apply absolute top-0 left-0 w-full h-full bg-black/70 flex items-center justify-center;
}

.icon {
    @apply flex items-center justify-center basis-[5rem] grow-0 shrink-0 h-20;
    @apply xsDesktop:basis-[7.5rem] xsDesktop:h-[7.5rem] shrink-0;

    img {
        @apply basis-[4.25rem] w-[4.25rem] grow-0 shrink-0 -rotate-45;
        @apply xsDesktop:basis-[6.875rem] xsDesktop:w-[6.875rem] shrink-0;
        animation: spinnerRotate 3s infinite;
    }
}

.text {
    @apply text-[1.375rem] leading-[1.2] mt-5 text-white;
}
