@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply w-12 h-12 cursor-pointer bg-white flex items-center justify-center;

    @include mixin.hover() {
        @apply hover:bg-[#FEF1F2] transition-colors duration-300;
    }

    img {
        @apply max-w-[1.25rem] w-full;
    }
}
