.header {
    @apply flex;
}
.headerItem {
    @apply flex items-center gap-2 py-3 px-4 text-[#003366] font-semibold text-[1rem] cursor-pointer;
    @apply transition-opacity duration-300;
}
.headerItemActive {
    @apply bg-white;
}
