.wrapper {
    @apply fixed z-[20] top-0 left-0 w-screen h-screen bg-black/70;
    @apply xsDesktop:flex xsDesktop:items-center xsDesktop:justify-center;
}

.body {
    @apply relative h-full w-full bg-white pt-40;
    @apply xsDesktop:h-[25rem] xsDesktop:w-[43.75rem] xsDesktop:pt-10 xsDesktop:px-8 xsDesktop:pb-9;

    &::before,
    &::after {
        @apply content-[''] absolute top-0 left-0 w-full h-full;
    }

    &::before {
        background: rgba(0, 51, 102, 0.1);
    }

    &::after {
        background: linear-gradient(
            177.42deg,
            #ffffff 3.72%,
            rgba(255, 255, 255, 0) 91.15%
        );
    }
}

.overflow {
    @apply z-[1] relative;
}

.content {
    @apply w-full h-full text-center px-5;
}

.icon {
    @apply mb-5 block mx-auto;
}

.title {
    @apply mb-6 text-[1.5rem] leading-[1.17] text-[#000E1F] font-semibold;
}

.text {
    @apply text-[0.875rem] text-[#000E1F] leading-[1.71] mb-20;
}
