@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply absolute top-4 left-4 w-[24rem] h-12;
    max-width: calc(100vw - 2rem);
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04),
        0 19px 30.38px rgba(0, 51, 102, 0.02),
        0 6px 9.04px rgba(0, 51, 102, 0.02);
}

.input {
    @apply w-full h-full px-11;
}

.item {
    @apply bg-white border-b border-b-black;
}

.search,
.closer {
    @apply absolute top-1/2 -translate-y-1/2;
}

.search {
    @apply left-4;
}

.closer {
    @apply right-4 cursor-pointer;

    @include mixin.hover() {
        @apply transition-opacity duration-300;
        @apply hover:opacity-70;
    }
}
