@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply absolute top-full left-0 w-full transition-transform duration-300;
    @apply xsDesktop:left-4 xsDesktop:top-20 xsDesktop:w-[24rem] xsDesktop:bottom-4;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04),
        0 19px 30.38px rgba(0, 51, 102, 0.02),
        0 6px 9.04px rgba(0, 51, 102, 0.02);
}

.open {
    @apply translate-y-[-20rem] w-full;
    @apply xsDesktop:w-[24rem] xsDesktop:translate-y-0;
}

.overflowHidden {
    @apply overflow-hidden h-[20rem] bg-white;
    @apply xsDesktop:h-full;
}

.overflow {
    @apply h-full;
}

.spacer {
    @apply h-full;
    @apply xsDesktop:h-11;
}

.item {
    @apply mx-4 my-2;
    @apply xsDesktop:mx-5 xsDesktop:my-2.5;

    &:first-of-type {
        @apply mt-4;
        @apply xsDesktop:mt-5;
    }

    &:last-of-type {
        @apply mb-4;
        @apply xsDesktop:mb-5;
    }
}

.toggler {
    @apply absolute z-[10] right-4 -top-7;
    @apply hidden;

    .open & {
        @apply flex;
        @apply xsDesktop:hidden;
    }
}

.noResults {
    @apply px-4 py-10 text-[#000E1F] text-[1rem] text-center;
}

.stateError {
    @apply px-4 py-10 text-[#000E1F] text-[1rem] text-center;

    a {
        @apply mt-8 inline-block py-2 px-4 bg-[#003366] rounded-[0.125rem] text-white font-bold;

        @include mixin.hover() {
            @apply transition-colors duration-300;
            @apply hover:bg-[#003366]/90;
        }
    }
}
