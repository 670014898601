.wrapper {
    @apply w-full h-full;
}

.wrapper div:has(img[src*='/img/layout/map-marker-trash']),
.wrapper div:has(img[src*='/img/layout/map-marker-clusterer']),
.wrapper div:has(img[src*='/img/layout/map-marker-collection']),
.wrapper div:has(img[src*='/img/layout/map-marker-bulky']) {
    @apply rounded-full;
    box-shadow: 0 16px 16px rgba(0, 51, 102, 0.08),
        0 5px 8px rgba(0, 51, 102, 0.16);
}
