@use 'src/assets/scss/abstract/mixin';

.mainUploader {
    @apply block pt-6 pb-3.5 px-4 tracking-[0.01em] text-[0.75rem] text-[#344054] leading-[1.5] text-center;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04);
}

.secUploader {
    @apply flex items-center justify-center;
}

.mainUploader,
.secUploader {
    @apply w-full bg-white border border-dashed border-[#C5D1E2] transition-[border] duration-300;

    &:focus {
        @apply outline-none;
    }

    @include mixin.hover() {
        @apply hover:border-[#EA0C36] bg-white;
    }
}

.mainUploaderThumb {
    @apply inline-block mb-2.5;
}

.mainUploaderCTA,
.mainUploaderText {
    @apply block;
}

.mainUploaderCTA {
    @apply text-[#ED0A34] underline;
}

.body {
    @apply grid grid-cols-3 gap-x-3;
}

.imgWrapper {
    @apply relative;

    &::before {
        @apply content-[''] pt-[100%] block;
    }
}

.img {
    @apply absolute top-0 right-0 w-full h-full object-cover object-center;
}

.imgRemover {
    @apply absolute top-0 right-0 w-[0.9375rem] translate-x-1/2 -translate-y-1/2 cursor-pointer;
}
