@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply relative basis-4 w-4 h-4 grow-0 shrink-0 border border-[#EDEDED] mr-2;
    @apply transition-[border] transition-[background] duration-300;

    &.radio {
        @apply rounded-full;
    }

    &.radio::after {
        @apply content-[''] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-1.5 h-1.5 bg-[#ED0A34] rounded-full;
        @apply opacity-0 transition-opacity duration-300;
    }

    &.radio.checked::after {
        @apply opacity-100;
    }

    &.checked,
    input:checked + [data-select-mark-parent] & {
        @apply border-[#ED0A34] bg-[#FCDEE0] bg-[#FCDEE0] font-normal;
    }

    @include mixin.hover() {
        [data-select-mark-parent]:hover & {
            @apply border-[#ED0A34] bg-[#FCDEE0] font-normal;
        }
    }

    [data-select-mark-parent]:focus &,
    input:focus + [data-select-mark-parent] & {
        @apply border-[#F98585] outline-none;
        box-shadow: 0 0 0 4px #fef1f2,
            0 18.7625px 30.3774px rgba(0, 51, 102, 0.0238443);
    }
}

.symbol {
    @apply absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 opacity-0;
    @apply transition-opacity duration-300;

    .checked &,
    input:checked + [data-select-mark-parent] .wrapper.input & {
        @apply opacity-100;
    }
}
