@use 'src/assets/scss/abstract/mixin';

.toggle {
    @apply flex items-center px-6 bg-white h-[2.5rem] border border-transparent cursor-pointer leading-none;
    @apply xsDesktop:h-[3.125rem] transition-[border,background] duration-300;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04);

    @include mixin.hover() {
        @apply hover:border-[#ED0A34] hover:leading-none;
    }

    .open & {
        @apply border-[#ED0A34] bg-[#FEF1F2] leading-none;
    }
}

.toggleIcon {
    @apply flex items-center justify-center basis-6 w-6 h-6 mr-3;
}

.toggleIconImg {
    @apply w-full h-full object-contain object-center;
}

.toggleText {
    @apply text-[#17181A] text-[1rem] font-medium;
}

.content {
    @apply absolute right-0 w-[15.625rem] overflow-hidden max-h-0 opacity-0 transition-[max-height,opacity] duration-500;
    top: calc(100% + 1rem);

    .open & {
        @apply max-h-[38rem] opacity-100;

        @media only screen and (max-height: 666px) and (max-width: 1279px) {
            @apply max-h-[45vh] overflow-auto;
        }

        @media only screen and (max-height: 700px) and (min-width: 1280px) {
            @apply max-h-[65vh] overflow-auto;
        }
    }
}

.wrapper .selectable {
    @apply xsDesktop:py-4 py-2;
}

.row {
    @apply xsDesktop:py-4 flex items-center justify-between px-6 py-2 bg-white cursor-pointer select-none w-full;
}

.col {
    @apply text-[#17181A] text-[0.875rem] font-normal;
}
