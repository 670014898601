.wrapper {
    @apply cursor-pointer w-[2.25rem] h-[1.25rem] bg-[#8792A0] block rounded-[10rem] relative;
}

.wrapper::after {
    @apply content-[''] absolute top-[2px] left-[2px] w-[1rem] h-[1rem] bg-white rounded-full;
    @apply transition-all duration-500 ease-in-out;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1),
        0 1px 2px rgba(16, 24, 40, 0.06);
}

.active {
    @apply bg-[#ED0A34] block;
}

.active::after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}
