.footer {
    @apply relative z-[1] bg-white;
}

.wrapper {
    @apply flex justify-between items-center max-w-[71.25rem] mx-auto px-4;
}

.logos {
    @apply flex items-center;
}

.logo {
    @apply mr-2;
}

.logoPrague {
    @apply w-9;
    @apply xsDesktop:w-12;
}

.logoOICT {
    @apply w-8;
    @apply xsDesktop:w-16;
}

.text {
    @apply xsDesktop:text-[0.75rem] text-[0.5rem] text-[#6E8CB8] font-normal;
}
