.fillFormWrapper .spinner {
    height: calc(100% + 15rem);
}

.title {
    @apply block text-[0.75rem] text-[#626D7A] uppercase font-medium;
}

.selectable {
    &:not(:last-of-type) {
        @apply mb-2;
    }
}

.btnWrapper {
    @apply text-right mt-8;
}

.formControl {
    @apply block w-full bg-white border border-[#C5D1E2] px-3.5 rounded-none;
    @apply transition-[border,box-shadow] duration-300;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

    &::placeholder {
        color: #98a1ad;
    }

    &:focus {
        @apply border-[#F98585] outline-none;
        box-shadow: 0 0 0 4px #fef1f2, 0 1px 2px rgba(16, 24, 40, 0.05);
    }
}

textarea.formControl {
    @apply min-h-[6.25rem] py-3 resize-none;
}

input.formControl {
    @apply h-11;
}

.formCheckbox {
    @apply flex items-start cursor-pointer;
}

.formCheckboxInput {
    @apply sr-only;
}

.formCheckboxLabel {
    @apply text-[0.875rem] text-[#344054] leading-[1.43] font-normal;

    .formCheckboxError & {
        color: #ed0a34;
    }

    a {
        @apply font-medium underline;
    }

    a:focus {
        @apply outline-none;
    }
}

.formCheckboxMark {
    @apply bg-white;

    .formCheckboxError & {
        @apply border-[#ED0A34] font-normal;
    }
}

.errorMsg {
    @apply text-[0.75rem] leading-[1.4] text-[#ED0A34] my-1;
}

.groupHeader {
    @apply relative py-5 pl-5 pr-10 bg-white cursor-pointer text-[#17181A] font-medium;

    .groupFilled & {
        @apply bg-[#FEF1F2] relative;
    }
}

.groupArrow {
    @apply absolute top-1/2 right-7 -translate-y-1/2 w-3;
    @apply transition-transform duration-300;

    .groupOpen & {
        @apply transform rotate-180;
    }
}

.groupBody {
    @apply overflow-hidden max-h-0 transition-[max-height] duration-300;

    .groupOpen & {
        @apply max-h-[20rem] duration-300;
    }
}
