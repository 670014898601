@use 'src/assets/scss/abstract/mixin';

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wrapper {
    @apply fixed z-20 top-0 left-0 w-screen h-screen bg-white overflow-hidden hidden;
    @apply xsDesktop:absolute xsDesktop:left-4 xsDesktop:top-4 xsDesktop:w-[24rem] xsDesktop:bottom-4 xsDesktop:h-auto;

    @screen xsDesktop {
        box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04);
    }

    &::before {
        @apply content-[''] absolute top-0 left-0 w-full h-full;
        background: rgba(0, 51, 102, 0.1)
            linear-gradient(
                177.42deg,
                #ffffff 3.72%,
                rgba(255, 255, 255, 0) 91.15%
            );

        @apply xsDesktop:hidden;
    }
}

.open {
    @apply block;
}

.openAnimated {
    animation: fadeIn 300ms;
}

.inner,
.back {
    @apply z-[1] relative;
}

.back {
    @apply flex items-center bg-white p-3 cursor-pointer;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04);

    @apply xsDesktop:bg-transparent;

    @screen xsDesktop {
        box-shadow: none;
    }

    @include mixin.hover() {
        @apply transition-opacity duration-300;
        @apply hover:opacity-70;
    }
}

.backArrow {
    @apply mr-2;
}

.backText {
    @apply text-[1rem] text-[#003366] leading-[1.29] tracking-[-0.408px] font-normal;
}

.inner {
    @apply overflow-hidden;
    height: calc(100vh - 2.8125rem);

    @screen xsDesktop {
        height: calc(100% - 2.8125rem);
    }
}

.overflow {
    @apply h-full;
}

.overflowInner {
    @apply px-4;
}
