@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply bg-[#ED0A34] py-1.5 px-5 text-white uppercase font-medium text-[1rem] leading-[1.5] inline-block;
    @apply xsDesktop:py-2.5;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

    @include mixin.hover() {
        @apply transition-colors duration-300;
        @apply hover:bg-[#bd0829] hover:text-white;
    }

    &:focus {
        @apply outline-none;
    }
}

.wrapperIcon {
    @apply inline-flex justify-center items-center;
}

.icon {
    @apply mr-2;
}
