@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply bg-white;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04),
        0 18.7625px 30.3774px rgba(0, 51, 102, 0.0238443),
        0 5.58609px 9.04414px rgba(0, 51, 102, 0.0161557);

    @include mixin.hover() {
        @apply transition-[box-shadow] duration-300;

        &:hover {
            box-shadow: none;
        }
    }

    & + & {
        @apply mt-0.5;
    }
}

.head {
    @apply relative px-4 py-2.5 flex items-center cursor-pointer;
}

.head .icon {
    @apply mr-2;
}

.title {
    @apply text-[1rem] text-[#003366] leading-[1.12] font-normal;
}

.arrow {
    @apply absolute right-4 top-5 transition-transform duration-300;

    .open & {
        @apply rotate-180;
    }
}

.body {
    @apply pl-4 pr-14 max-h-0 overflow-hidden;
    transition: 0.25s max-height, 0.25s padding;

    .open & {
        @apply pt-3 max-h-[100rem] pb-6;
    }
}

.text {
    & + & {
        @apply mt-2.5;
    }
}

.title {
    @apply text-[#003366] text-[0.875rem] leading-[1.71] font-normal;
}

.paragraph {
    @apply text-[#6E8CB8] text-[0.75rem] leading-[1.33] mt-1;
}
