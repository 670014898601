.wrapper {
    @apply w-full h-full transition-opacity duration-300;
}

.invisible {
    @apply opacity-0;
}

.controls {
    @apply absolute bottom-7 right-4 transition-[bottom] duration-300;
    @apply flex gap-4 items-end;
}

.controlsItem {
    > *:not(:first-of-type) {
        @apply mt-4;
    }
}

.controlsOpen {
    @media only screen and (max-width: 1279px) {
        display: none;
    }
}

.controlList {
    .controlsOpen & {
        @apply hidden;
    }
}

.filter {
    @apply absolute top-20 right-4;
    @apply xsDesktop:top-4;
}

.wrapper .controlsLinks {
    @apply absolute bottom-0 right-0 w-full opacity-0 translate-y-full transition-[transform,opacity] duration-500;
    @apply xsDesktop:w-auto xsDesktop:bottom-7 xsDesktop:right-20;
}

.wrapper .controlsLinksOpen {
    @apply translate-y-0 opacity-100;
}
