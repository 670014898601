@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply w-12 h-12 mt-4 flex items-center justify-center bg-white cursor-pointer;
    @apply transition-colors duration-300;

    @include mixin.hover() {
        @apply hover:bg-[#FEF1F2] duration-300;
    }
}

.open {
    @apply border-[#ED0A34] bg-[#FEF1F2] border;
    box-shadow: 0 5.586px 9.044px 0 rgba(0, 51, 102, 0.02);
}
