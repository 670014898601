@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply bg-white py-3 px-8 flex items-center cursor-pointer;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04),
        0 18.7625px 30.3774px rgba(0, 51, 102, 0.0238443);

    @include mixin.hover() {
        @apply transition-[box-shadow] duration-300;

        &:hover {
            box-shadow: none;
        }
    }
}

.header {
    @apply basis-6 grow-0 shrink-0 mr-4;
}

.name {
    @apply text-[#000E1F] text-[1rem] tracking-[-0.078px] font-medium;
}

.distance {
    @apply mt-1;
}

.containers {
    @apply mt-2 flex flex-wrap gap-1.5;
}
