@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply flex w-full items-center justify-between text-left gap-4 bg-white cursor-pointer;
}

.type-default {
    @apply py-4 px-5 border border-transparent;
    @apply transition-[border] duration-300;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04);

    @include mixin.hover() {
        @apply hover:border-[#ED0A34] hover:bg-white;
    }
}

.type-default:focus {
    @apply border-[#F98585] outline-none;
    box-shadow: 0 0 0 4px #fef1f2,
        0 18.7625px 30.3774px rgba(0, 51, 102, 0.0238443);
}

.type-default.checked {
    @apply border-[#ED0A34] bg-[#FEF1F2] font-normal;
}

.type-small {
    @apply py-3 mx-5;
    max-width: calc(100% - 2.5rem);

    &:not(:last-child) {
        @apply border-b-[#EDEDED] border-b;
    }
}

.header {
    @apply flex items-center;
}

.iconWrapper {
    @apply flex items-center justify-center basis-6 grow-0 shrink-0 w-6 h-6 mr-4;
}

.icon {
    @apply max-h-full max-w-full object-contain object-center;
}

.label {
    @apply text-[#17181A] text-[0.875rem] font-medium;
}
