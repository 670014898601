@use 'src/assets/scss/abstract/mixin';

.spacer {
    @apply block h-40 w-full;
}

.header {
    @apply bg-white px-4 pb-4 pt-6 mb-6;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04);
}

.headerRow {
    @apply flex items-center gap-x-4;

    &:first-of-type {
        @apply mb-6;
    }

    & + & {
        @apply mb-4;
    }
}

.headerRowLink {
    @apply relative cursor-pointer;
}

.headerRowLinkArrow {
    @apply absolute right-0 top-1/2 -translate-y-1/2;

    @include mixin.hover() {
        @apply transition-[right] duration-300;
        .headerRowLink:hover & {
            @apply -right-1;
        }
    }
}

.headerCol {
    &:first-of-type {
        @apply basis-6 grow-0 shrink-0;

        img {
            @apply w-full;
        }
    }

    &:last-of-type {
        flex: 1;
    }
}

.headerTitle {
    @apply text-[1rem] text-[#6E8CB8] tracking-[-0.078px] font-medium;
}

.headerText {
    @apply text-[0.875rem] text-[#000E1F] leading-[1.71] font-normal;
}

.wrapper .headerDistance {
    @apply mt-1.5;
}

.headerBtn {
    @apply flex items-center justify-center bg-white border border-[#ED0A34] rounded-[0.25rem] py-1.5;
    @apply text-[#ED0A34] text-[0.75rem] tracking-[-0.0632211px] font-medium cursor-pointer;
    box-shadow: 0 68.0842px 110.232px rgba(0, 51, 102, 0.04);

    @include mixin.hover() {
        @apply transition-colors duration-300;
        @apply hover:bg-[#ED0A34] hover:text-white;

        &:hover img {
            filter: brightness(0) invert(1);
        }
    }

    img {
        @apply w-4 mr-2;

        @include mixin.hover() {
            @apply transition-[filter] duration-300;
        }
    }
}

.bulkyStations {
    @apply space-y-4;
}

.bulkyTitle {
    @apply text-[0.75rem] text-[#626D7A] uppercase mb-2 font-semibold;
}

.bulkyBox {
    @apply bg-white p-4 space-y-4;
    box-shadow: 0 5.586px 9.044px 0 rgba(0, 51, 102, 0.02);
}

.bulkyItem {
    @apply flex text-[#000E1F] text-[0.875rem] items-center;
}

.bulkyIconWrapper {
    @apply basis-6 max-w-[1.5rem] grow-0 shrink-0 mr-3 flex items-center justify-center;
}

.bulkyIcon {
    @apply object-contain object-center;
}

.alert {
    @apply relative p-4 bg-white -mx-4;
    box-shadow: 0 5.586px 9.044px 0 rgba(0, 51, 102, 0.02);
}

.alertCloser {
    @apply absolute top-2 right-2 w-9 cursor-pointer transition-transform duration-300;
    @include mixin.hover() {
        @apply hover:scale-125;
    }
}

.alertGroup {
    @apply text-[0.875rem] font-medium pr-16;
}

.alertTitle {
    @apply text-[#17181A] mb-1.5;
}

.alertText {
    @apply text-[#464F5C] mb-4;
}

.alertButton {
    @apply bg-[#488515] border border-[#488515] text-center text-white w-full py-1.5 transition-colors duration-300;

    @include mixin.hover() {
        @apply hover:text-[#488515] hover:border-[#488515] hover:bg-white;
    }
}

.text {
    & + & {
        @apply mt-2.5;
    }
}

.title {
    @apply text-[#003366] text-[0.875rem] leading-[1.71] font-normal;
}

.paragraph {
    @apply text-[#6E8CB8] text-[0.75rem] leading-[1.33] mt-1;
}

