// target touch devices
@mixin touch {
    @media (hover: none), (pointer: coarse) {
        @content;
    }
}

// target non-touch devices
@mixin hover {
    @media not all and (pointer: coarse) {
        @content;
    }
}

// hide scrollbar
@mixin hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
