@use 'src/assets/scss/abstract/vars' as *;
/* roboto-regular - latin-ext_latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('#{$path-font}/roboto/roboto-v30-latin-ext_latin-regular.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('#{$path-font}/roboto/roboto-v30-latin-ext_latin-regular.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin-ext_latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('#{$path-font}/roboto/roboto-v30-latin-ext_latin-500.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('#{$path-font}/roboto/roboto-v30-latin-ext_latin-500.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin-ext_latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('#{$path-font}/roboto/roboto-v30-latin-ext_latin-700.woff2')
            format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
            url('#{$path-font}/roboto/roboto-v30-latin-ext_latin-700.woff')
            format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
