@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply bg-white w-12;
    box-shadow: 0 84px 136px rgba(0, 51, 102, 0.04);
}

.icon {
    @apply flex items-center justify-center w-full px-1.5 cursor-pointer;

    @include mixin.hover() {
        @apply hover:bg-[#FEF1F2] transition-colors duration-300;
    }
}

.plus {
    @apply pt-[0.875rem] h-[2.875rem] pb-3;
}

.minus {
    @apply pt-2 pb-3 h-10;
}

.divider {
    @apply border-none block bg-[#B3B9C4]/20 h-[0.125rem] mx-auto;
    width: calc(100% - 0.75rem);

    @include mixin.hover() {
        .wrapper:hover & {
            @apply bg-[#FEF1F2] w-full;
        }
    }
}
