.wrapper {
    @apply relative inline-block w-6;
}

.progress {
    @apply absolute -top-0.5 -left-0.5;
}

.progress {
    @apply w-7 h-7 rounded-full;
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));

    path[class='CircularProgressbar-path'] {
        stroke: #64c579;
    }
}

.progressWarning {
    path[class='CircularProgressbar-path'] {
        stroke: #ed0a34;
    }
}
