@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply relative bg-white pb-4;
    @apply xsDesktop:pb-0;
}

.main {
    @apply p-4 grid grid-cols-3 gap-x-7 gap-y-6;
    @apply xsDesktop:grid-cols-[repeat(3,5.875rem)] xsDesktop:p-2 xsDesktop:gap-2;
    box-shadow: 0 5.586px 9.044px 0 rgba(0, 51, 102, 0.02);
}

.link {
    @apply relative grid items-start text-center py-4 px-2 mb-10;
    box-shadow: 0 5.586085796356201px 9.04413890838623px 0
            rgba(0, 51, 102, 0.02),
        0px 18.76249885559082px 30.37738037109375px 0 rgba(0, 51, 102, 0.02);

    @apply xsDesktop:mb-0;

    @include mixin.hover() {
        @apply transition-colors duration-300;

        &:hover {
            @apply bg-[#ED0A34]/10;
        }
    }
}

.linkActives {
    @apply border border-[#ED0A34] pointer-events-none;
}

.linkIcon {
    @apply w-6 h-6 mx-auto object-contain object-center mb-5;
}

.linkText {
    @apply min-h-[2.25rem] text-[0.75rem] text-[#000E1F] w-full;
}

.title {
    @apply col-span-full uppercase text-[0.75rem] font-semibold text-[#626D7A] w-full;
    @apply xsDesktop:hidden;
}

.closeWrapper {
    @apply absolute right-4 top-0 -translate-y-1/2 w-12 h-12 flex items-center justify-center bg-white;
    box-shadow: 0 5.586px 9.044px 0 rgba(0, 51, 102, 0.02);
    @apply xsDesktop:hidden;
}
