@use 'src/assets/scss/abstract/mixin';

.wrapper {
    @apply p-4 bg-white hidden;
    box-shadow: 0 5.586px 9.044px 0 rgba(0, 51, 102, 0.02);
}

.show {
    @apply block;
}

.item {
    @apply flex items-center gap-3;

    & + & {
        @apply mt-4;
    }
}

.header {
    @apply flex items-center justify-center h-6 basis-6 grow-0 shrink-0;
}

.icon {
    @apply max-h-full max-w-full object-contain object-center;
}

.body {
    @apply text-[0.875rem] text-[#000E1F] block;
}

.label {
    @apply font-bold;
}

.nav {
    @apply flex items-center justify-between gap-4 px-3 mt-10 mb-5;
}

.navItem {
    @apply uppercase text-[0.875rem] text-[#6E84A3] cursor-pointer font-medium;
    @apply border-b-4 border-b-transparent;

    @include mixin.hover {
        @apply hover:text-[#528FDF] transition-colors duration-300;
    }
}

.navItemActive {
    @apply border-b-[#528FDF] uppercase;
}

.infoWrapper {
    @apply mt-6 flex items-start gap-3;
}

.infoIcon {
    @apply basis-6 w-6 grow-0 shrink-0;
}

.infoText {
    @apply text-[0.875rem] text-[#6E84A3] font-normal;
}
